<template>
  <section class="freight-wrapper">
    <card title="运费管理">
      <el-button
        class="card-btn"
        size="small"
        type="primary"
        @click="editFreight"
        >{{ closeFlag ? "完成" : "编辑" }}</el-button
      >
      <span class="freight-col">
        收费方式:
        <el-radio-group v-model="type" @change="changeType">
          <el-radio-button :label="0">全部地区包邮</el-radio-button>
          <el-radio-button :label="1">满{{ price }}元包邮</el-radio-button>
          <el-radio-button :label="2">分地区计费</el-radio-button>
        </el-radio-group>
      </span>
      <br />

      <span class="freight-col">
        价格设置:满
        <el-popover
          placement="bottom"
          trigger="hover"
          content="点击右上角编辑按钮进行编辑"
          :disabled="closeFlag"
        >
          <el-input-number
            v-model="price"
            :precision="2"
            :step="0.1"
            :disabled="!closeFlag"
            slot="reference"
          ></el-input-number>
        </el-popover>
        元包邮
      </span>
      <div class="freightTable-wrapper">
        <table class="freightTable">
          <tr class="tableHeader">
            <td>地区</td>
            <td>价格</td>
            <td>地区</td>
            <td>价格</td>
            <td>地区</td>
            <td>价格</td>
          </tr>
          <tr v-for="index in rowCount" :key="index">
            <td class="column">{{ areaList[index * 3 - 3].province }}</td>
            <td>
              <el-input
                placeholder="请输入内容"
                v-show="closeFlag"
                v-model="areaList[index * 3 - 3].money"
                size="small"
              ></el-input>
              <span v-show="!closeFlag" style="line-height:32px">{{
                areaList[index * 3 - 3].money
              }}</span>
            </td>
            <td class="column">
              {{
                areaList[index * 3 - 2] !== undefined
                  ? areaList[index * 3 - 2].province
                  : ""
              }}
            </td>
            <td>
              <el-input
                placeholder="请输入内容"
                v-if="areaList[index * 3 - 2] !== undefined"
                v-show="closeFlag"
                v-model="areaList[index * 3 - 2].money"
                size="small"
              ></el-input>
              <span v-show="!closeFlag" style="line-height:32px">{{
                areaList[index * 3 - 2] !== undefined
                  ? areaList[index * 3 - 2].money
                  : ""
              }}</span>
            </td>
            <td class="column">
              {{
                areaList[index * 3 - 1] !== undefined
                  ? areaList[index * 3 - 1].province
                  : ""
              }}
            </td>
            <td>
              <el-input
                placeholder="请输入内容"
                v-if="areaList[index * 3 - 1] !== undefined"
                v-show="closeFlag"
                v-model="areaList[index * 3 - 1].money"
                size="small"
              ></el-input>
              <span v-show="!closeFlag" style="line-height:32px">{{
                areaList[index * 3 - 1] !== undefined
                  ? areaList[index * 3 - 1].money
                  : ""
              }}</span>
            </td>
          </tr>
        </table>
      </div>
    </card>
  </section>
</template>

<script>
import card from "../../components/card";
export default {
  components: {
    card
  },
  data() {
    return {
      type: 3,
      price: 0,
      closeFlag: false,
      areaList: []
    };
  },
  computed: {
    rowCount: function() {
      return Math.ceil(this.areaList.length / 3);
    }
  },
  methods: {
    areaFilter(x) {
      return this.areaList.filter((item, index) => {
        return index % 3 === x;
      });
    },
    editFreight() {
      if (this.closeFlag === true) {
        Promise.all([
          this.changeShippingAmount(),
          this.changeAreaAmount()
        ]).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "修改成功"
            });
          } else {
            this.$message({
              type: "error",
              message: res.data.msg
            });
          }
        });
      }
      this.closeFlag = !this.closeFlag;
    },
    changeShippingAmount() {
      return this.$axios({
        url: "/changeShippingAmount",
        params: {
          amount: this.price
        }
      });
    },
    changeAreaAmount() {
      return this.$axios({
        url: "/updateShipping",
        method: "post",
        transformRequest: [
          function(data) {
            return JSON.stringify(data);
          }
        ],
        data: this.areaList,
        headers: { "Content-Type": "application/json" }
      });
    },
    changeType() {
      this.$axios({
        url: "/changeShippingType",
        params: {
          type: this.type
        }
      })
        .then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "修改收费方式成功"
            });
          } else {
            this.$message({
              type: "error",
              message: "请求发生错误"
            });
          }
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: "修改失败，请检查网络"
          });
        });
    },
    getShipping(type) {
      return this.$axios({
        url: "/getShippingAmount",
        params: {
          type: type
        }
      })
        .then(res => {
          if (res.data.code == 200) {
            if (type == 1) {
              this.price = res.data.data;
            } else this.areaList = res.data.data;
          } else {
            this.$message({
              type: "error",
              message: "请求发生错误"
            });
          }
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: "信息获取失败"
          });
        });
    },
    getType() {
      return this.$axios({
        url: "/getShippingType"
      })
        .then(res => {
          if (res.data.code == 200) {
            this.type = res.data.data;
          } else {
            this.$message({
              type: "error",
              message: "请求发生错误"
            });
          }
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: "收费方式获取失败"
          });
        });
    }
  },
  created() {
    this.$openLoading();
    Promise.all([
      this.getShipping(1),
      this.getShipping(2),
      this.getType()
    ]).then(res => {
      setTimeout(() => {
        this.$openLoading().close();
      }, 200);
    });
  }
};
</script>

<style lang="less">
.freight-wrapper {
  font-size: 14px;

  .freight-col {
    margin-bottom: 10px;
    display: block;
  }
  .freightTable-wrapper {
    overflow: scroll;
    margin-top: 30px;

    .freightTable {
      font-size: 14px;
      color: #606266;
      border-collapse: collapse;
      width: 100%;
      text-align: center;

      .tableHeader {
        color: #909399;
        font-weight: 700;
      }
      tr {
        td {
          border: 1px solid #e6eaee;
          width: 150px;
          height: 45px;
          line-height: 45px;
          box-sizing: border-box;
          padding: 0 10px;
        }
        .column {
          color: #393c3e;
        }
      }
    }
  }
}
</style>
